<template>
  <v-dialog 
    transition="dialog-top-transition"
    v-model="showDialog" width="70%"> 
        <v-card class="card_home_container info_pagamento_dialog_card">
            <v-card-title class="text-h6">                        
                <v-row class="TitleDialog d-flex" justify="center" align="left" >
                  <v-col cols="1">
                    <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-info-sul-opagamento.svg')" />
                  </v-col>
                  <v-col cols="9" class="">
                      <span class="dialog_title">{{translateLabel('message.bolletta_interattiva_template5_info_pagamento_dialog_title')}}</span>
                  </v-col>
                  <v-col cols="1" class=".float-end">
                    <img :onerror="hideParent()" class="dialog_img" :src="getImg('/images/bollettainterattiva/Template5/Core/ico-exit.svg')"  v-on:click="closeDialog()" />
                  </v-col>
                </v-row>
            </v-card-title>

          <v-card-text class="text-subtitle-1 pa-5 ">
              <p class="info_pagamento_dialog_text overflow-hidden" v-html='translateLabel("message.bolletta_interattiva_template5_info_pagamento_dialog_text")'></p>
          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import utility from '../Core/utility';

export default {
  mixins: [utility],
  data() {
    return {
    }
  },
 computed: {

  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
        showDialog:{
          type: Boolean,
          default: false
        }
    },
  methods:{
      closeDialog: function(){
          this.$emit('close');        
      }
  }

}
</script>

<style>

</style>